import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  DiscountType,
  IAddressForProposal,
  ICustomerAddressForProposal,
  IProposal,
} from "../models/IProposal";
import {
  formatDateForDisplay,
  getZonedDateTimeForDisplay,
} from "../services/dateServices";
import { formatCurrency } from "../services/currencyFormatter";
import { isStringSet } from "../services/stringService";
import { IInvoice } from "../models/IInvoice";
import { PrintContainer } from "../common/PrintContainer";
import { PrintImages } from "../common/PrintImages";

interface IProps {
  proposal: IProposal;
  depositInvoice: IInvoice | null;
  paymentMethodOnFileAuthorized: boolean;
  onLoadComplete: () => void;
}

const ProposalPrint: React.FunctionComponent<IProps> = ({
  proposal,
  depositInvoice,
  paymentMethodOnFileAuthorized,
  onLoadComplete,
}) => {
  useEffect(() => {
    if (!proposal.logo) {
      onLoadComplete();
    }
  }, [proposal, onLoadComplete]);

  const hasOptionalLineItems = proposal.lineItems.some((li) => li.optional);

  const showSummaryAmounts =
    !hasOptionalLineItems || proposal.acceptanceDateTime;

  return (
    <PrintContainer
      printTypeTitleCased="Proposal"
      printTypeLowerCased="proposal"
    >
      <>
        <div>
          <div className="billing-item-print-header billing-item-print-content">
            <div>
              <div data-testid="tenantName">{proposal.tenantName}</div>
              <div data-testid="tenantContactPhone">
                {proposal.tenantContactPhoneNumber}
              </div>
              <div data-testid="tenantEmailAddress">
                {proposal.tenantEmailAddress}
              </div>
              <Address
                testPrefix="tenant"
                address={proposal.tenantAddressComponents}
              />
            </div>
            <div className="text-center">
              {proposal.logo ? (
                <img
                  src={proposal.logo.imageUrl}
                  width={proposal.logo.width}
                  height={proposal.logo.height}
                  alt="Company Logo"
                  onLoad={() => {
                    onLoadComplete();
                  }}
                  onError={() => {
                    onLoadComplete();
                  }}
                />
              ) : null}
            </div>
            <div className="text-right">
              {proposal.number ? (
                <div data-testid="purchaseOrderNumber">
                  PO #: {proposal.number}
                </div>
              ) : null}
              <div data-testid="date">
                Date: {formatDateForDisplay(proposal.proposalDate)}
              </div>
              <div data-testid="validUntilDate">
                Valid until: {formatDateForDisplay(proposal.validUntilDate)}
              </div>

              {typeof proposal.acceptanceDateTime === "object" &&
              proposal.acceptanceDateTime !== null ? (
                <div className="mt-2" data-testid="acceptanceContainer">
                  <div className="text-success">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    Accepted
                  </div>
                  <div data-testid="acceptanceDetails">
                    {getZonedDateTimeForDisplay(proposal.acceptanceDateTime)}
                  </div>
                </div>
              ) : typeof proposal.rejectedDateTime === "object" &&
                proposal.rejectedDateTime !== null ? (
                <div className="mt-2" data-testid="rejectedContainer">
                  <div className="text-danger">
                    <FontAwesomeIcon icon={faXmarkCircle} className="mr-1" />
                    Rejected
                  </div>
                  <div data-testid="rejectionDetails">
                    {getZonedDateTimeForDisplay(proposal.rejectedDateTime)}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="border billing-item-print-box">
            <div className="p-2">
              <div data-testid="customerName">{proposal.customerName}</div>
              <Address
                testPrefix="customer"
                address={proposal.customerAddressComponents}
              />
            </div>
          </div>

          <div>
            {isHtmlTextSet(proposal.introTextHtml) ? (
              <div
                data-testid="introText"
                className="mt-4"
                dangerouslySetInnerHTML={{
                  // HTML was safely generated on server
                  __html: proposal.introTextHtml,
                }}
              ></div>
            ) : null}

            {isStringSet(proposal.summary) ? (
              <div
                data-testid="summary"
                className="mt-2"
                style={{ whiteSpace: "pre-line" }}
              >
                {proposal.summary}
              </div>
            ) : null}

            {proposal.jobAddressComponents !== null &&
            isAddressSet(proposal.jobAddressComponents) ? (
              <div className="pt-3" data-testid="jobAddressContainer">
                <div className="font-weight-bold">Job location</div>
                {isStringSet(proposal.jobAddressName) ? (
                  <div data-testid="jobLocationName">
                    {proposal.jobAddressName}
                  </div>
                ) : null}
                <div data-testid="jobAddress">
                  {proposal.jobAddressComponents.streetAndNumber}
                  {getAddressComponentSeparator(
                    proposal.jobAddressComponents.streetAndNumber,
                    proposal.jobAddressComponents.apartmentSuite ?? "",
                    " "
                  )}
                  {isStringSet(proposal.jobAddressComponents.apartmentSuite)
                    ? proposal.jobAddressComponents.apartmentSuite
                    : null}
                  {getAddressComponentSeparator(
                    proposal.jobAddressComponents.apartmentSuite ||
                      proposal.jobAddressComponents.streetAndNumber,
                    proposal.jobAddressComponents.city
                  )}
                  {proposal.jobAddressComponents.city}
                  {getCityStateSeparator(proposal.jobAddressComponents)}{" "}
                  {proposal.jobAddressComponents.state}{" "}
                  {proposal.jobAddressComponents.zip}
                </div>
              </div>
            ) : null}

            <RejectionAndDepositDetails
              proposal={proposal}
              depositInvoice={depositInvoice}
              paymentMethodOnFileAuthorized={paymentMethodOnFileAuthorized}
            />

            {paymentMethodOnFileAuthorized ? (
              <CenterContent>
                <div
                  className="text-success"
                  data-testid="paymentMethodSavedIndicator"
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                  Payment method saved
                </div>
              </CenterContent>
            ) : null}

            {isRequiredPaymentMethodOnFileMissing({
              proposal,
              paymentMethodOnFileAuthorized,
            }) &&
            // Don't show this message if the deposit invoice isn't paid since we'll combine the
            // payment method on file message with the deposit invoice not paid message
            (depositInvoice === null || depositInvoice.paid) ? (
              <CenterContent>
                <div data-testid="paymentMethodRequiredIndicator">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="mr-1 text-danger"
                  />
                  In order to schedule work, we'll need to collect a payment
                  method on file.
                </div>
              </CenterContent>
            ) : null}

            <div
              data-testid="lineItemContainer"
              className="mt-3"
              style={{
                display: "grid",
                gap: "20px 15px",
                gridTemplateColumns: `${
                  hasOptionalLineItems ? "min-content" : ""
                } 2.5fr min-content ${
                  !proposal.hideLineItemPrices ? "min-content min-content " : ""
                }`,
              }}
            >
              {hasOptionalLineItems ? (
                <div data-testid="optionalItemHeader">&nbsp;</div>
              ) : null}

              <div
                data-testid="nameHeader"
                className="font-weight-bold text-nowrap border-bottom"
              >
                Product / Service
              </div>

              <div className={"font-weight-bold text-right border-bottom"}>
                Quantity
              </div>
              {!proposal.hideLineItemPrices ? (
                <>
                  <div
                    className={
                      "font-weight-bold text-right text-nowrap border-bottom"
                    }
                  >
                    Unit price
                  </div>
                  <div className={"font-weight-bold text-right border-bottom"}>
                    Total
                  </div>
                </>
              ) : null}
              {proposal.lineItems.map((li, liIndex) => {
                const columnStyle = {
                  marginTop: liIndex === 0 ? "-15px" : undefined,
                };

                return (
                  <React.Fragment key={li.id}>
                    {hasOptionalLineItems ? (
                      <div
                        style={{ ...columnStyle, alignSelf: "start" }}
                        data-testid="optionalItemCheckbox"
                      >
                        {li.optional ? (
                          <input
                            type="checkbox"
                            className="mt-1"
                            // Don't allow changes since it's for printing. Not disabling
                            // since the checkbox that are printed won't look right
                            onChange={() => {}}
                            checked={
                              proposal.acceptanceDateTime !== null &&
                              li.selected
                            }
                          />
                        ) : null}
                      </div>
                    ) : null}

                    <div style={columnStyle}>
                      <div data-testid="lineItemName">{li.name}</div>
                      <div
                        data-testid="lineItemDescription"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {li.description}
                      </div>
                    </div>
                    <div
                      className={"text-right"}
                      data-testid="lineItemQuantity"
                      style={columnStyle}
                    >
                      {li.quantity}
                    </div>

                    {!proposal.hideLineItemPrices ? (
                      <>
                        <div
                          className={"text-right"}
                          data-testid="lineItemAmountPerItem"
                          style={columnStyle}
                        >
                          {typeof li.amountPerItem === "number"
                            ? formatCurrency(li.amountPerItem, true)
                            : null}
                        </div>
                        <div
                          className={"text-right"}
                          data-testid="lineItemTotal"
                          style={columnStyle}
                        >
                          {typeof li.lineItemTotal === "number"
                            ? formatCurrency(li.lineItemTotal, true)
                            : null}
                        </div>
                      </>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>

            <div className="mt-3 d-flex justify-content-end">
              <table>
                <tbody>
                  <tr>
                    <td>Subtotal: </td>
                    <td className="pl-3 text-right" data-testid="subtotal">
                      {showSummaryAmounts
                        ? formatCurrency(proposal.subtotal)
                        : null}
                    </td>
                  </tr>
                  {typeof proposal.discountAmount === "number" ? (
                    <tr data-testid="discountAmountContainer">
                      <td>Discount: </td>
                      <td
                        className="pl-3 text-right"
                        data-testid="discountAmount"
                      >
                        {showSummaryAmounts ? (
                          <>({formatCurrency(proposal.discountAmount)})</>
                        ) : proposal.discount?.type === DiscountType.percent &&
                          typeof proposal.discount.percent === "number" &&
                          proposal.discount.percent > 0 ? (
                          `${proposal.discount.percent * 100}%`
                        ) : null}
                      </td>
                    </tr>
                  ) : null}
                  {proposal.taxAmount ? (
                    <tr>
                      <td>Taxes: </td>
                      <td className="pl-3 text-right" data-testid="taxAmount">
                        {showSummaryAmounts
                          ? formatCurrency(proposal.taxAmount ?? 0)
                          : null}
                      </td>
                    </tr>
                  ) : null}
                  {areDepositFieldsSet(proposal) ? (
                    <>
                      <tr>
                        <td data-testid="depositAmountLabel">
                          Initial deposit:
                        </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="depositAmount"
                        >
                          {showSummaryAmounts
                            ? formatCurrency(proposal.depositAmount)
                            : null}
                        </td>
                      </tr>
                      {proposal.balanceDueAtCompletion && showSummaryAmounts ? (
                        <tr>
                          <td data-testid="balanceDueLabel">
                            Balance due at completion:
                          </td>
                          <td
                            className="pl-3 text-right"
                            data-testid="balanceDue"
                          >
                            {formatCurrency(proposal.balanceDueAtCompletion)}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  ) : null}
                  <tr className="border-top">
                    <td>Total: </td>
                    <td className="pl-3 text-right" data-testid="total">
                      {showSummaryAmounts
                        ? formatCurrency(proposal.total)
                        : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {isStringSet(proposal.signatureImagePath) ? (
              <div data-testid="signatureContainer">
                <div className="mt-2">
                  By providing a signature below, I accept the proposal for the
                  work to be performed under the terms and conditions attached
                  to this proposal
                </div>
                <img
                  src={`${proposal.filePrefix}/${proposal.signatureImagePath}`}
                  alt="Signature"
                  data-testid="signatureImage"
                />
              </div>
            ) : proposal.acceptanceDateTime === null &&
              proposal.rejectedDateTime === null ? (
              <div className="d-flex mt-5" data-testid="signaturePlacholder">
                <div className="d-inline-block" style={{ width: "40%" }}>
                  <hr className="mb-0" />
                  <div className="text-center">
                    <small>Customer signature</small>
                  </div>
                </div>
                <div className="d-inline-block ml-5" style={{ width: "20%" }}>
                  <hr className="mb-0" />
                  <div className="text-center">
                    <small>Date</small>
                  </div>
                </div>
              </div>
            ) : null}

            <PrintImages
              files={proposal.files}
              filePrefix={proposal.filePrefix}
            />

            {isHtmlTextSet(proposal.footerTextHtml) ? (
              <div
                data-testid="footerText"
                className="mt-5"
                dangerouslySetInnerHTML={{
                  // HTML was safely generated on server
                  __html: proposal.footerTextHtml,
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </>
    </PrintContainer>
  );
};

export default ProposalPrint;

function Address({
  testPrefix,
  address,
}: {
  testPrefix: string;
  address: IAddressForProposal | ICustomerAddressForProposal | null;
}) {
  if (!address) {
    return null;
  }

  let cityStateSeparator = getCityStateSeparator(address);
  return (
    <>
      <div data-testid={`${testPrefix}AddressLineOne`}>
        {address.streetAndNumber}

        {"apartmentSuite" in address && isStringSet(address.apartmentSuite) ? (
          <> {address.apartmentSuite}</>
        ) : null}
      </div>

      <div data-testid={`${testPrefix}AddressLineTwo`}>
        {address.city}
        {cityStateSeparator}
        {address.state} {address.zip}
      </div>
    </>
  );
}

function RejectionAndDepositDetails({
  proposal,
  depositInvoice,
  paymentMethodOnFileAuthorized,
}: {
  proposal: IProposal;
  depositInvoice: IInvoice | null;
  paymentMethodOnFileAuthorized: boolean;
}) {
  if (
    proposal.acceptanceDateTime === null &&
    proposal.rejectedDateTime !== null &&
    isStringSet(proposal.rejectedReason)
  ) {
    return (
      <CenterContent>
        <div data-testid="rejectionReasonContainer">
          <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />
          <span className="text-danger ml-2 font-weight-bold">
            Rejection Reason:{" "}
          </span>
          {proposal.rejectedReason}
        </div>
      </CenterContent>
    );
  } else if (proposal.acceptanceDateTime && depositInvoice) {
    if (depositInvoice.paid) {
      return (
        <CenterContent>
          <div data-testid="depositPaymentReceived">
            <div className="text-success" data-testid="depositReceived">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
              Deposit payment of {formatCurrency(depositInvoice.total)} received
            </div>

            {depositInvoice.transactionDateTime !== null ? (
              <div data-testid="depositDatePaid">
                on{" "}
                {getZonedDateTimeForDisplay(depositInvoice.transactionDateTime)}
              </div>
            ) : null}

            {depositInvoice.convenienceFeePaid ? (
              <div data-testid="depositConvenienceFeeAmount">
                an additional{" "}
                {formatCurrency(depositInvoice.convenienceFeePaid)} was charged
                as a convenience fee
              </div>
            ) : null}
          </div>
        </CenterContent>
      );
    } else {
      let message = !isRequiredPaymentMethodOnFileMissing({
        proposal,
        paymentMethodOnFileAuthorized,
      })
        ? `In order to schedule work, we'll need to collect a deposit payment of ${formatCurrency(
            depositInvoice.total
          )}.`
        : `In order to schedule work, a deposit payment of ${formatCurrency(
            depositInvoice.total
          )} and a payment method on file will need to be collected.`;
      return (
        <CenterContent>
          <div data-testid="depositPaymentMissing">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="mr-1 text-danger"
            />
            {message}
          </div>
        </CenterContent>
      );
    }
  } else {
    return null;
  }
}

function isRequiredPaymentMethodOnFileMissing({
  proposal,
  paymentMethodOnFileAuthorized,
}: {
  proposal: IProposal;
  paymentMethodOnFileAuthorized: boolean;
}) {
  return (
    proposal.captureCustomerPaymentMethod &&
    !proposal.captureCustomerPaymentMethodOptional &&
    !paymentMethodOnFileAuthorized
  );
}

function CenterContent({ children }: { children: React.ReactNode }) {
  return (
    <div className="mt-3 d-flex justify-content-center">
      <div style={{ maxWidth: "75%" }}>{children}</div>
    </div>
  );
}

interface IProposalWithDeposit extends IProposal {
  depositAmount: number;
}

function getCityStateSeparator(address: IAddressForProposal) {
  return getAddressComponentSeparator(address.city, address.state);
}

function getAddressComponentSeparator(
  propOne: string,
  propTwo: string,
  separateToUse: string = ", "
) {
  let separator = "";
  if (isStringSet(propOne) && isStringSet(propTwo)) {
    separator = separateToUse;
  }
  return separator;
}

function areDepositFieldsSet(
  proposal: IProposal
): proposal is IProposalWithDeposit {
  return typeof proposal.depositAmount === "number";
}

function isHtmlTextSet(input: string) {
  return (
    isStringSet(input) &&
    input !== "<div><div style='padding-bottom: 1rem;'></div></div>"
  );
}

function isAddressSet(jobAddress: IAddressForProposal | null) {
  if (!jobAddress) {
    return false;
  }

  return (
    isStringSet(jobAddress.streetAndNumber) ||
    isStringSet(jobAddress.city) ||
    isStringSet(jobAddress.state) ||
    isStringSet(jobAddress.zip)
  );
}
