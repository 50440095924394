import { ICommonLineItem } from "./models/ICommonLineItem";
import ResponsiveGridTable, {
  responsiveGridBreakpoint,
  ResponsiveGridTableDisplayType,
} from "./ResponsiveGridTable";
import { formatCurrency } from "./services/currencyFormatter";
import { formatDateForDisplay } from "./services/dateServices";
import { isStringSet } from "./services/stringService";

export default function LineItemDisplay({
  lineItems,
  hideLineItemPrices,
  setLineItems,
  disableSelected,
  failedSelectionUpdateRowIndex,
}: {
  lineItems: Array<ICommonLineItem>;
  hideLineItemPrices: boolean;
  setLineItems?: (
    newLineItems: Array<ICommonLineItem>,
    rowIndex: number
  ) => void;
  disableSelected?: boolean;
  failedSelectionUpdateRowIndex?: number | null;
}) {
  const proposalLineItemRows = lineItems.map((li) => ({
    ...li,
    key: li.id,
  }));

  const hasServiceDateSet = lineItems.some((li) => isStringSet(li.serviceDate));

  function updateLineItem<T>(
    onLineItemChanged: (updatedItem: ICommonLineItem) => void,
    lineItem: ICommonLineItem,
    propName: keyof ICommonLineItem,
    propValue: T
  ) {
    onLineItemChanged({
      ...lineItem,
      [propName]: propValue,
    });
  }

  const buildOnLineItemChanged = (li: ICommonLineItem, rowIndex: number) => {
    return (newLineItem: ICommonLineItem) => {
      if (typeof setLineItems === "function") {
        setLineItems(
          lineItems.map((lineItemToUpdate) => {
            if (lineItemToUpdate.id === li.id) {
              return newLineItem;
            } else {
              return lineItemToUpdate;
            }
          }),
          rowIndex
        );
      }
    };
  };

  return (
    <ResponsiveGridTable
      gap="20px 20px"
      columns={[
        {
          header: "Service date",
          width: "min-content",
          cell: (lineItem, rowIndex, displayType) => (
            <div style={getContainerStyle({ rowIndex, displayType })}>
              {isStringSet(lineItem.serviceDate)
                ? formatDateForDisplay(lineItem.serviceDate)
                : ""}
            </div>
          ),
          key: "serviceDate",
          cellTestId: "lineItemServiceDate",
          hidden: !hasServiceDateSet,
          className: "text-nowrap",
          headerClassName: "border-bottom",
        },
        {
          header: "Product / Service",
          width: "auto",
          cell: (lineItem, rowIndex, displayType) => (
            <div style={getContainerStyle({ rowIndex, displayType })}>
              <div>
                <div className="d-flex">
                  {lineItems.some((li) => li.optional) ? (
                    <div
                      className={
                        `custom-control custom-checkbox ` +
                        // Toggle display for small viewports since we don't want the space to be preserved when using
                        // the non-grid layout
                        (!lineItem.optional
                          ? `d-none d-${responsiveGridBreakpoint}-block`
                          : "")
                      }
                      style={{
                        visibility: !lineItem.optional ? "hidden" : undefined,
                      }}
                    >
                      <input
                        id={`Selected${rowIndex}`}
                        type="checkbox"
                        className={"custom-control-input"}
                        checked={lineItem["selected"] ?? false}
                        onChange={(e) => {
                          if (lineItem.optional) {
                            updateLineItem(
                              buildOnLineItemChanged(lineItem, rowIndex),
                              lineItem,
                              "selected",
                              e.target.checked
                            );
                          }
                        }}
                        data-testid={`Selected${rowIndex}`}
                        disabled={disableSelected}
                      />
                      <label
                        htmlFor={`Selected${rowIndex}`}
                        data-testid={`SelectedLabel${rowIndex}`}
                        className="custom-control-label"
                      >
                        <span className="sr-only"></span>
                      </label>
                    </div>
                  ) : null}
                  <div>
                    {isStringSet(lineItem.name) ? (
                      <div data-testid="lineItemName">
                        <label htmlFor={`Selected${rowIndex}`}>
                          {lineItem.name}
                        </label>
                      </div>
                    ) : null}
                    <div
                      data-testid="lineItemDescription"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {lineItem.description}
                    </div>
                  </div>
                </div>
              </div>
              {failedSelectionUpdateRowIndex === rowIndex ? (
                <div>
                  <small className="text-danger">
                    There was an error selecting the item. Please try again.
                  </small>
                </div>
              ) : null}
            </div>
          ),
          key: "name",
          className: "text-nowrap",
          headerClassName: "border-bottom",
        },
        {
          header: "Quantity",
          width: "min-content",
          cell: (lineItem, rowIndex, displayType) => (
            <div style={getContainerStyle({ rowIndex, displayType })}>
              {lineItem.quantity}
            </div>
          ),
          key: "quantity",
          className: "text-right",
          cellTestId: "lineItemQuantity",
          headerClassName: "border-bottom",
        },
        {
          header: "Unit price",
          width: "min-content",
          cell: (lineItem, rowIndex, displayType) => (
            <div style={getContainerStyle({ rowIndex, displayType })}>
              {lineItem.amountPerItem !== null
                ? formatCurrency(lineItem.amountPerItem, true)
                : ""}
            </div>
          ),
          key: "amountPerItem",
          className: "text-right",
          hidden: hideLineItemPrices,
          cellTestId: "lineItemAmountPerItem",
          headerClassName: "border-bottom",
        },
        {
          header: "Total",
          width: "min-content",
          contentClassName: "text-nowrap",
          cell: (lineItem, rowIndex, displayType) => (
            <div style={getContainerStyle({ rowIndex, displayType })}>
              <div>
                {lineItem.lineItemTotal !== null
                  ? formatCurrency(lineItem.lineItemTotal, true)
                  : ""}
              </div>
              {lineItem.optional ? (
                <>
                  <div style={{ marginTop: "-5px" }}>
                    {!lineItem.selected ? (
                      <small>Not included</small>
                    ) : lineItem.selected ? (
                      <small className="text-success font-weight-bold">
                        Included
                      </small>
                    ) : null}
                  </div>{" "}
                </>
              ) : null}
            </div>
          ),
          key: "lineItemTotal",
          className: "text-right",
          hidden: hideLineItemPrices,
          cellTestId: "lineItemTotal",
          headerClassName: "border-bottom",
        },
      ]}
      rows={proposalLineItemRows}
    />
  );
}

function getContainerStyle({
  rowIndex,
  displayType,
}: {
  rowIndex: number;
  displayType: ResponsiveGridTableDisplayType;
}) {
  if (rowIndex === 0 && displayType === "desktop") {
    return {
      marginTop: "-15px",
    };
  } else {
    return {};
  }
}
