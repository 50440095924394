const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatCurrency(
  input: number,
  showNegativeNumbersInParentheses: boolean = false
) {
  if (input < 0 && showNegativeNumbersInParentheses) {
    return `(${formatter.format(input * -1)})`;
  } else {
    return formatter.format(input);
  }
}

const formatterWithoutDecimalDigits = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCurrencyWithoutDecimalDigits(input: number) {
  return formatterWithoutDecimalDigits.format(input);
}
