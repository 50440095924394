const hostnameLowercase = window.location.hostname.toLowerCase();

let urlBase: string;
let appInsightsConnectionString = "";
if (
  hostnameLowercase === "localhost" ||
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  urlBase = "https://localhost:44350";
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_LOCAL as string;
} else if (
  hostnameLowercase ===
    "greenerpastures-staging-frontend-customer.azurewebsites.net" ||
  hostnameLowercase ===
    "greenerpastures-staging-frontend-customer.azureedge.net"
) {
  urlBase = "https://greenerpastures-staging-api.azurewebsites.net";
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_TEST as string;
} else {
  urlBase = "https://api.crewcontrol.us";
  appInsightsConnectionString = process.env
    .REACT_APP_APP_INSIGHTS_CONNECTION_STRING_PROD as string;
}

const configuration = {
  urlBase,
  appInsightsConnectionString,
};

export default configuration;
